import React, { useEffect } from 'react';
import { useState } from 'react';

import PropTypes from 'prop-types';

import { isDebug } from 'utils/tools';

const ShowDebug = ({ data, readOnly, console, label }) => {
	const [showDebug, setShowDebug] = useState(false);
	const [message, setMessage] = useState(null);
	const [activated, setActivated] = useState(false);

	useEffect(() => {
		if (console && isDebug) {
			if (activated) {
				window.console.log('showDebug:', data);
				setMessage('olhar na console');
				setTimeout(() => {
					setMessage(null);
				}, 1500);
			} else {
				setActivated(true); // na primeira renderização não loga na console
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showDebug]);

	return isDebug && !readOnly ? (
		<>
			<button type="button" className="btn-debug" onClick={() => !message && setShowDebug(old => !old)}>
				{message ? (
					<span>{message}</span>
				) : console ? (
					<span>log {label || 'debug data'}</span>
				) : showDebug ? (
					<span>Hide {label || 'debug data'}</span>
				) : (
					<span>Show {label || 'debug data'}</span>
				)}
			</button>
			{showDebug && !console && !readOnly && (
				<>
					<h5>{label || 'DEBUG DATA'}</h5>
					<pre>{JSON.stringify(data, null, 2)}</pre>
				</>
			)}
		</>
	) : null;
};
ShowDebug.displayName = 'ShowDebug';
ShowDebug.propTypes = {
	data: PropTypes.any,
	readOnly: PropTypes.bool,
	console: PropTypes.bool,
	label: PropTypes.string
};

export default ShowDebug;
