import React from 'react';

import PropTypes from 'prop-types';

import TemplateRenderer from 'components/TemplateRenderer';

const Label = ({ id, text, selected, children, onClick, debug, linkHelp }) => (
	<>
		<div onClick={onClick} className={`option-enquadramento${selected ? ' selected' : ''}`}>
			<span>{text}</span>
			{(debug || linkHelp) && (
				<span
					className="numero-enquadramento"
					style={{ fontSize: '8pt', fontWeight: 'bold', color: '#fa970f', marginRight: '10px' }}
				>
					{linkHelp && (
						<TemplateRenderer templatePath={linkHelp}>
							<i
								className="fa fa-info-circle ml-2"
								style={{ fontSize: '20px', position: 'relative', right: '10px' }}
							></i>
						</TemplateRenderer>
					)}
					{debug && id}
				</span>
			)}
			{children}
		</div>
	</>
);
Label.displayName = 'Id';
Label.propTypes = {
	id: PropTypes.string,
	text: PropTypes.string,
	selected: PropTypes.bool,
	debug: PropTypes.bool,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
	onClick: PropTypes.func,
	linkHelp: PropTypes.string
};

export default Label;
