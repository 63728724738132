import React, { useCallback, useMemo, useState } from 'react';

import PropTypes from 'prop-types';

import { capitalize, concat, get, size, sortBy, trim } from 'lodash';
import moment from 'moment';

import ErrorMessages from 'components/ErrorMessages';

import DocumentoAdesaoPrchpa, { obtemAdesaoPrchpaDados } from 'containers/AdesaoPrchpa';
import AprovacaoProjetosDocEspecifico from 'containers/AprovacaoProjetos';
import { UAP_LABELS_ENUM } from 'containers/AprovacaoProjetos/TabelaAreasAP/constantsAP';
import ArrazoadoEvu, { obtemArrazoadoDados } from 'containers/ArrazoadoEvu';
import CertidaoDemolicaoDocEspecifico, { obtemCertidaoDemolicaoDados } from 'containers/CertidaoDemolicao';
import DocumentoCertificacaoSustentavel, {
	obtemCertificacaoSustentavelDados
} from 'containers/CertificacaoSustentavel';
import LicencasExpressasDocEspecifico, { obtemFormularioLicencasExpressasDados } from 'containers/LicencasExpressas';
import LicenciamentoExpressoDocEspecifico, {
	obtemFormularioLicenciamentoExpressoDados
} from 'containers/LicenciamentoExpresso/LicencimentoExpressoDocEspecifico';
import RequerimentoIndices, { obtemRequerimentoIndicesDados } from 'containers/RequerimentoIndices';
import DocumentoVistoriaHabitese, { obtemVistoriaDados } from 'containers/VistoriaHabitese';

import { API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { calculaNumeroLinhas, isDebug, joinBr, obtemDadosDocumento } from 'utils/tools';

import {
	EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU,
	EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO,
	EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL,
	EXTENSAO_DOC_ESPECIFICO_HABITESE,
	EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS,
	EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO,
	EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS,
	EXTENSAO_DOC_ESPECIFICO_UDRI,
	EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
	EXTENSOES_DOCUMENTOS_ESPECIFICOS,
	EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA
} from '../Detalhe';
import { escondeBotaoAddMultiDocs } from './ComplementacaoBpmTask';
import DocumentoPlanilhaEdifGerais from './DocumentoPlanilhaEdifGerais';
import { DocumentoSlotModal } from './DocumentoSlotModal';
import DocumentoUpload from './DocumentoUpload';

const DocumentosSlots = ({
	formulario,
	documentos,
	documentosDados,
	perguntas,
	disponivel,
	motivos,
	errors,
	usuarioDonoTask,
	prontoParaComplementar,
	criarMultiDocumentos,
	emProcessamento,
	onDrop,
	removeFile,
	limpaDocumentosDados,
	retiraDocumento,
	criarNovoDocumento,
	updateResposta,
	complementarProcesso,
	avancar,
	emComplementacao
}) => {
	let nomeEnquadramentoLE = '';
	let nomeEnquadramentoAP = '';

	const [questoesEnquadramento, setQuestoes] = useState();

	const carregaMetadataEnquadramento = useCallback(async idMetadata => {
		try {
			let questionsAux = null;
			{
				const retorno = (await accessApi(`/collections/metadata/${idMetadata}`))?.data;
				questionsAux = retorno || {};
			}
			setQuestoes(questionsAux?.questions[0]?.questions);
		} catch (e) {
			console.error('Erro ao obter dados do enquadramento!', e);
		}
	}, []);

	const enquadramento = useMemo(() => formulario?.formData?.data?.enquadramento, [formulario]);
	const definicao = useMemo(() => enquadramento?.definicao, [enquadramento]);

	const tituloCertificacaoSustentavel = useMemo(
		() =>
			`Formulário de Certificação em Sustentabilidade Ambiental${
				definicao ? ` para Projeto ${capitalize(definicao)}` : ''
			}`,
		[definicao]
	);

	const tituloCertidaoDemolicao = useMemo(
		() => `Requerimento para Certidão de Demolição${definicao ? ` ${capitalize(definicao)}` : ''}`,
		[definicao]
	);

	return (
		<>
			{sortBy(documentos, 'ordem')
				.filter(d => !d.invisible)
				.map((d, i) => {
					const arquivoGerado = !!d.filename || size(get(formulario, 'formData.resultado')) > 0;

					const enquadramento = get(formulario, 'formData.data.enquadramento');
					if (d.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO) {
						let questions = get(enquadramento, 'metadados.questions', []);

						// Se ainda nao tem as questoes do enquadramento na store, carrega do banco
						if (size(questions) < 1 && !questoesEnquadramento) {
							carregaMetadataEnquadramento('licenciamento-expresso-enquadramento');
						}

						if (size(questions) > 0 || size(questoesEnquadramento) > 0) {
							const idEnquadramento = get(enquadramento, 'respostas.1', '11');
							const metaQuestion = (questoesEnquadramento || questions).find(questao => questao.id === idEnquadramento);
							nomeEnquadramentoLE = get(metaQuestion, 'label', '');
						}
					} else if (d.extensao === EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS) {
						if (enquadramento && enquadramento.definicao && size(enquadramento.definicao) > 0) {
							nomeEnquadramentoAP = (enquadramento.definicao || '').split(',').reduce((acc, def) => {
								const novoLabel = UAP_LABELS_ENUM[def];
								if (novoLabel) {
									return [...acc, novoLabel];
								}
								return acc;
							}, []);
							nomeEnquadramentoAP = joinBr(nomeEnquadramentoAP);
						}
					}

					return (
						<div className="form-group col-md-12" key={d.id || i}>
							<label htmlFor={`inputFile${d.id}`}>
								<span style={{ display: 'flex', justifyContent: 'space-between' }}>
									<span>
										{d.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL
											? tituloCertificacaoSustentavel
											: d.tituloDocumento || d.tituloDocumentoAdmin}
										{d.obrigatorio ? <span style={{ color: 'red' }}>*</span> : ''}
										{d.versao && d.versao !== 1 ? ` (v. ${d.versao})` : ''}
									</span>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										{isDebug && (
											<span className="debug-message">
												({d.idDocumento} - {d.ordem})
											</span>
										)}
									</div>
								</span>

								{d.descricaoDocumento && (
									<span style={{ fontSize: '1.0rem', color: 'grey' }}>{d.descricaoDocumento}</span>
								)}
							</label>
							{get(formulario, 'formData.expirado') ? null : d.extensao ===
							  EXTENSAO_DOC_ESPECIFICO_PLANILHA_EDIFICACOES_GERAIS ? (
								<DocumentoPlanilhaEdifGerais
									documento={d}
									planilha={(documentosDados || []).find(dd => dd.id === d.id)}
									usuarioDonoTask={usuarioDonoTask}
									removeFile={removeFile}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Arrazoado EVU em rascunho"
									labelDocumentoInvalidado="Corrigir o documento de Arrazoado EVU"
									labelCriarDocumento="Criar Arrazoado EVU"
									modalTitulo="Arrazoado EVU"
									documento={d}
									documentoDados={obtemArrazoadoDados(formulario)}
									usuarioDonoTask={usuarioDonoTask}
									componentDocumento={ArrazoadoEvu}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_ARRAZOADO_EVU}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_HABITESE ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Documento de Vistoria em rascunho"
									labelDocumentoInvalidado="Corrigir o documento"
									labelCriarDocumento="Criar Formulário Habite-se"
									modalTitulo="Formulário Habite-se"
									documento={d}
									documentoDados={obtemVistoriaDados(formulario)}
									usuarioDonoTask={usuarioDonoTask}
									componentDocumento={DocumentoVistoriaHabitese}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_HABITESE}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_HABITESE}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário Licenciamento Expresso em rascunho"
									labelCriarDocumento="Criar Formulário Licenciamento Expresso"
									modalTitulo={'Licenciamento Expresso'}
									modalSubTitulo={nomeEnquadramentoLE}
									documento={d}
									documentoDados={obtemFormularioLicenciamentoExpressoDados(formulario)}
									usuarioDonoTask={usuarioDonoTask}
									componentDocumento={LicenciamentoExpressoDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_LICENCIAMENTO_EXPRESSO}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário Licenças para obras de simples natureza em rascunho"
									labelCriarDocumento="Criar Formulário Licenças para obras de simples natureza"
									modalTitulo={'Licenças para obras de simples natureza'}
									modalSubTitulo={nomeEnquadramentoLE}
									documento={d}
									documentoDados={obtemFormularioLicencasExpressasDados(formulario)}
									usuarioDonoTask={usuarioDonoTask}
									componentDocumento={LicencasExpressasDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_LICENCAS_EXPRESSAS}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_UDRI ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Requerimento de Índices em rascunho"
									labelDocumentoInvalidado="Corrigir o Requerimento de Índices"
									labelCriarDocumento="Criar Requerimento de Índices"
									modalTitulo="Requerimento de Índices"
									documento={d}
									documentoDados={obtemRequerimentoIndicesDados(formulario)}
									usuarioDonoTask={usuarioDonoTask}
									componentDocumento={RequerimentoIndices}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_UDRI}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_UDRI}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário para Certificação em Sustentabilidade Ambiental em rascunho"
									labelDocumentoInvalidado="Corrigir o formulário para Certificação em Sustentabilidade Ambiental"
									labelCriarDocumento="Criar formulário para Certificação em Sustentabilidade Ambiental"
									modalTitulo={tituloCertificacaoSustentavel}
									documento={d}
									documentoDados={obtemCertificacaoSustentavelDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={DocumentoCertificacaoSustentavel}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_CERTIFICACAO_SUSTENTAVEL}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO ? (
								<DocumentoSlotModal
									labelDocumentoRascunho={`Documento específico para solicitação de certidão de demolição${
										definicao ? ` ${definicao}` : ''
									} em rascunho`}
									labelDocumentoInvalidado={`Corrigir o documento específico para solicitação de certidão ${definicao}`}
									labelCriarDocumento={`Criar Documento específico para solicitação de certidão ${definicao}`}
									modalTitulo={tituloCertidaoDemolicao}
									documento={d}
									documentoDados={obtemCertidaoDemolicaoDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={CertidaoDemolicaoDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_CERTIDAO_DEMOLICAO}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS ? (
								<DocumentoSlotModal
									labelDocumentoRascunho={'Documento específico para aprovação de projeto arquitetônico em rascunho'}
									labelDocumentoInvalidado={'Corrigir o documento específico para aprovação de projeto arquitetônico'}
									labelCriarDocumento={'Criar documento específico para aprovação de projeto arquitetônico'}
									modalTitulo={'Aprovação de Projeto Arquitetônico'}
									modalSubTitulo={nomeEnquadramentoAP}
									documento={d}
									documentoDados={obtemDadosDocumento(
										formulario,
										EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
										false,
										false
									)}
									usuarioDonoTask={true}
									componentDocumento={AprovacaoProjetosDocEspecifico}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									requerimentoEmRascunho={!formulario.formData.procedimentoFormatadoSei}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS}
								/>
							) : d.extensao === EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA ? (
								<DocumentoSlotModal
									labelDocumentoRascunho="Formulário em rascunho"
									labelDocumentoInvalidado="Corrigir o formulário"
									labelCriarDocumento="Criar o formulário"
									modalTitulo="Formulário para Adesão ao Programa de Reabilitação Urbana do Centro Histórico de Porto Alegre"
									documento={d}
									documentoDados={obtemAdesaoPrchpaDados(formulario)}
									usuarioDonoTask={true}
									componentDocumento={DocumentoAdesaoPrchpa}
									urlEmComplementacao={`${API_URL}/processo/${formulario?.formData?.id}/${EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA}`}
									removeFile={removeFile}
									limpaDocumentosDados={limpaDocumentosDados}
									tipoFormularioEspecifico={EXTENSAO_DOC_ESPECIFICO_ADESAO_PRCHPA}
								/>
							) : (
								<DocumentoUpload
									formData={formulario?.formData}
									documento={d}
									emProcessamento={emProcessamento(d)}
									arquivoGerado={arquivoGerado}
									onDrop={onDrop}
									removeFile={removeFile}
									retiraDocumento={retiraDocumento}
									procedimentoFormatadoSei={formulario?.formData?.procedimentoFormatadoSei}
									usuarioDonoTask={usuarioDonoTask}
									emComplementacao={emComplementacao}
								/>
							)}
							{disponivel && !EXTENSOES_DOCUMENTOS_ESPECIFICOS.includes(d.extensao) && (
								<button
									hidden={d.ordem % 100 !== 0 || !d.filename || escondeBotaoAddMultiDocs(documentos, d)}
									type="button"
									className="btn-link"
									onClick={() => criarMultiDocumentos(d)}
								>
									<i className="fa fa-plus" style={{ fontSize: '18px' }} />
									{`  Adicionar outro documento de ${d.tituloDocumento || d.tituloDocumentoAdmi}`}
								</button>
							)}
							<ErrorMessages errorList={concat(get(motivos, d.id) || [], get(errors, d.id) || [])} />
						</div>
					);
				})}
			{disponivel && (
				<div className="mt-3 mb-4">
					<button type="button" className="btn-secondary" onClick={() => criarNovoDocumento()}>
						Adicionar documento
					</button>
				</div>
			)}
			{size(perguntas) > 0 && perguntas.reduce((acc, info) => acc || size(trim(info.pergunta)) > 0, false) && (
				<>
					<div className="col alert warning">
						<h3>
							Complementação de informações <i className="fa fa-exclamation-circle" />
						</h3>
						<p>O revisor de seu processo fez observações sobre seu requerimento.</p>
						{formulario?.formData?.expirado && (
							<p style={{ color: 'red' }}>
								{`Entretanto, seu prazo para complementação expirou em ${moment(
									formulario?.formData?.dataComparecimento
								).format('DD/MM/YYYY')}, então você não pode mais complementar seu
								requerimento.`}
							</p>
						)}
					</div>
					{perguntas
						.filter(info => size(trim(info)) > 0)
						.map(info => (
							<div className="row" key={info.id}>
								<div className="col-md-12">
									<textarea
										className="form-control"
										value={info.pergunta}
										disabled={true}
										rows={(info.pergunta || '').split('\n').length + 1}
									></textarea>
									<textarea
										style={{ marginBottom: '10px' }}
										className="form-control"
										type="text"
										rows={calculaNumeroLinhas(info.resposta)}
										placeholder="Observações"
										value={info.resposta || ''}
										onChange={e => {
											const resposta = get(e, 'target.value') || '';
											updateResposta(info.id, resposta);
										}}
										readOnly={formulario?.formData?.expirado}
										disabled={formulario?.formData?.disabled || !usuarioDonoTask}
									></textarea>
								</div>
								<ErrorMessages errorList={get(errors, info.id) || false} />
							</div>
						))}
				</>
			)}

			{documentosDados?.reduce((acc, certAlmejada) => acc || certAlmejada?.dados?.seloAlmejado === 'Bronze', false) &&
				formulario.formData.data.enquadramento.definicao === 'proposto' && (
					<div className="alert alert-info">
						<span>
							{/* <i
								className="fa fa-info-circle"
								style={{ marginLeft: '10px', position: 'absolute', fontSize: '26px', left: '12px', top: '17px' }}
							/> */}
							A pontuação da Certificação Bronze não contempla o benefício de acréscimo da altura máxima para projeto
							proposto, mas não impede a solicitação da Certificação em Sustentabilidade Ambiental para outros
							benefícios.
						</span>
					</div>
				)}

			{prontoParaComplementar ? (
				usuarioDonoTask ? (
					<button type="button" className="btn-primary" onClick={() => complementarProcesso()}>
						Complementar processo
					</button>
				) : null
			) : !formulario?.formData?.expirado ? (
				usuarioDonoTask ? (
					avancar ? (
						<button type="button" className="btn-primary" onClick={avancar}>
							Avançar
						</button>
					) : null
				) : null
			) : null}
		</>
	);
};
DocumentosSlots.displayName = 'DocumentosSlots';
DocumentosSlots.propTypes = {
	formulario: PropTypes.object,
	documentos: PropTypes.arrayOf(PropTypes.object),
	documentosDados: PropTypes.arrayOf(PropTypes.object),
	perguntas: PropTypes.arrayOf(PropTypes.object),
	disponivel: PropTypes.bool,
	motivos: PropTypes.object,
	errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.bool]),
	usuarioDonoTask: PropTypes.bool,
	prontoParaComplementar: PropTypes.bool,
	criarMultiDocumentos: PropTypes.func,
	emProcessamento: PropTypes.func,
	onDrop: PropTypes.func,
	removeFile: PropTypes.func,
	limpaDocumentosDados: PropTypes.func,
	retiraDocumento: PropTypes.func,
	criarNovoDocumento: PropTypes.func,
	updateResposta: PropTypes.func,
	complementarProcesso: PropTypes.func,
	avancar: PropTypes.func,
	emComplementacao: PropTypes.bool
};

export default DocumentosSlots;
