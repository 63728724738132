export const LOAD_FORM_AND_DATA = 'app/Form/LOAD_FORM_AND_DATA';
export const SET_FORMULARIO = 'app/Form/SET_FORMULARIO';
export const SET_FORM_DATA = 'app/Form/SET_FORM_DATA';
export const SEND_FORM_DATA = 'app/Form/SEND_FORM_DATA';
export const SET_ENQUADRAMENTO_DATA = 'app/Form/SET_ENQUADRAMENTO_DATA';
export const SEND_ENQUADRAMENTO_DATA = 'app/Form/SEND_ENQUADRAMENTO_DATA';
export const SET_FORM_DATA_DATA = 'app/Form/SET_FORM_DATA_DATA';
export const SEND_FORM_COMPLETO = 'app/Form/SEND_FORM_COMPLETO';
export const COMPLEMENTAR_PROCESSO = 'app/Form/COMPLEMENTAR_PROCESSO';
export const SET_RELOAD = 'app/Form/SET_RELOAD';
export const SET_RESULT = 'app/Form/SET_RESULT';
export const UPDATE_FORM_ID = 'app/Form/UPDATE_FORM_ID';
export const UPDATE_FORM_DATA = 'app/Form/UPDATE_FORM_DATA';
export const UPLOAD_FILE = 'app/Form/UPLOAD_FILE';
export const UPLOAD_PROGRESS = 'app/Form/UPLOAD_PROGRESS';
export const UPLOAD_SUCCESS = 'app/Form/UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'app/Form/UPLOAD_FAILURE';
export const ADD_NEW_DOCUMENT = 'app/Form/ADD_NEW_DOCUMENT';
export const RETIRA_NEW_DOCUMENT = 'app/Form/RETIRA_NEW_DOCUMENT';
export const REMOVE_FILE = 'app/Form/REMOVE_FILE';
export const REMOVE_FILE_STORE = 'app/Form/REMOVE_FILE_STORE';
export const SET_LOADING = 'app/Form/SET_LOADING';
export const SET_ERRORS = 'app/Form/SET_ERRORS';
export const LIMPA_STORE = 'app/Form/LIMPA_STORE';
export const SET_MOTIVO_DOC = 'app/Form/SET_MOTIVO_DOC';
export const ATUALIZA_DOCS_INVALIDOS = 'app/Form/ATUALIZA_DOCS_INVALIDOS';
export const SET_DATA_COMPARECIMENTO = 'app/Form/SET_DATA_COMPARECIMENTO';
export const TOGGLE_DOC = 'app/Form/TOGGLE_DOC';
export const SET_USER_AGREEMENT = 'app/Form/SET_USER_AGREEMENT';
export const UPDATE_STATUS_DOCUMENTO = 'app/Form/UPDATE_STATUS_DOCUMENTO';
export const DUPLICAR_REQUERIMENTO = 'app/Form/DUPLICAR_REQUERIMENTO';
export const UPDATE_RESPOSTA_EXTRAINFO = 'app/Form/UPDATE_RESPOSTA_EXTRAINFO';
export const OBTAIN_BPM_TASKS = 'app/Form/OBTAIN_BPM_TASKS';
export const SET_BPM_TASKS = 'app/Form/SET_BPM_TASKS';
export const SET_KC_USER = 'app/Form/SET_KC_USER';
export const SUBMIT_ABA_DAM = 'app/Form/ABA_DAM';
export const SALVA_ANEXO_LIST = 'app/SALVA_ANEXO_LIST';
export const CLEAR_ANEXO_LIST = 'app/CLEAR_ANEXO_LIST';
export const SET_PERSISTENT_STATE = 'app/SET_PERSISTENT_STATE';
export const LOAD_USUARIO = 'app/LOAD_USUARIO';
export const SET_USUARIO = 'app/SET_USUARIO';
export const SET_USUARIO_INTERNO = 'app/SET_USUARIO_INTERNO';
export const LIMPAR_DOCUMENTOS_DADOS = 'app/Form/LIMPAR_DOCUMENTOS_DADOS';

export const SERVER_SAVE_FORM_DATA = 'formulario/SERVER_SAVE_FORM_DATA';
export const SERVER_SAVE_ENQUADRAMENTO_DATA = 'formulario/SERVER_SAVE_ENQUADRAMENTO_DATA';
export const SERVER_SAVE_FORM_COMPLETO = 'formulario/SERVER_SAVE_FORM_COMPLETO';
export const SERVER_COMPLEMENTAR_PROCESSO = 'formulario/SERVER_COMPLEMENTAR_PROCESSO';
export const GERA_PROCESSO_SEI = 'formulario/GERA_PROCESSO_SEI';
export const SERVER_REMOVE_FILE = 'formulario/SERVER_REMOVE_FILE';
export const SERVER_INVALIDA_DOCUMENTO = 'formulario/SERVER_INVALIDA_DOCUMENTO';
