import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useSelector } from 'react-redux';

import { isImmutable } from 'immutable';
import { get, size } from 'lodash';
import sanitizeHtml from 'sanitize-html';

import {
	EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
	TIPO_FORMULARIO_APROVACAO_PROJETOS,
	TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL,
	TIPO_FORMULARIO_HABITE_SE,
	TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO
} from 'containers/Form/Detalhe';
// import { obtemFormularioLicencasExpressasDados } from 'containers/LicencasExpressas/';
import { CheckboxSubfield } from 'containers/LicencasExpressas/CheckboxSubfield';
import { obtemFormularioLicenciamentoExpressoDados } from 'containers/LicenciamentoExpresso/LicencimentoExpressoDocEspecifico';
import { LE_Enquadramentos, TODOS_ENQUADRAMENTOS } from 'containers/LicenciamentoExpresso/MetadataLE';
import { METAVISTORIA, obtemVistoriaDados } from 'containers/VistoriaHabitese';

import useMutableState from 'custom-hooks/useMutableState';

import { obtemDadosDocumento } from 'utils/tools';

import ErrorMessages from './ErrorMessages';
import { termoRestricoes } from './RestricoesAdministrativasForm';

const TermosCondicoes = props => {
	const {
		showDeclaracao = true,
		showAceitar = false,
		aceitarLabel = 'Eu aceito e concordo',
		avancarLabel = 'Avançar',
		readOnly = false,
		onAvancarHandler,
		onAceitarHandler,
		concordo,
		termo: termoResponsabilidadeProps,
		termoNotificacao: termoNotificacaoProps
	} = props;

	const errors = useMutableState(['licenciamento', 'errors']);

	// recebe o formulario da store, de dentro da raiz licenciamento como immutable e imediatamente seta como JS no estado
	const [formulario, setFormulario] = useState(null);
	const formularioImm = useSelector(state => state.getIn(['licenciamento', 'formulario']));
	useEffect(() => {
		if (formularioImm) {
			setFormulario(isImmutable(formularioImm) ? formularioImm.toJS() : formularioImm);
		}
	}, [formularioImm]);

	const [termoNotificacao, setTermoNotificacao] = useState(termoNotificacaoProps);
	const [termoResponsabilidade, setTermoResponsabilidade] = useState(termoResponsabilidadeProps || '');

	const [isTermoResponsabilidadeSetado, setIsTermoResponsabilidadeSetado] = useState(false);

	useEffect(() => {
		/**
		 * termo de notificação da vistoria habite-se dinâmico
		 */
		if (formulario?.idTipoFormulario === TIPO_FORMULARIO_HABITE_SE) {
			const documentoDados = obtemVistoriaDados(formulario);
			let isVistoriaTotal =
				get(documentoDados, `dados.${METAVISTORIA.tipoVistoria.name}`) === METAVISTORIA.TIPOS_VISTORIA.total.codigo;

			setTermoNotificacao(old => {
				const declaracao = isVistoriaTotal
					? ''
					: '<p>Declaro que estão atendidos integralmente os requisitos do art. 25 do Decreto 18.623/2014</p>';
				let html = `${old} ${declaracao}`;
				html = sanitizeHtml(html, { allowedAttributes: { '*': ['class', 'style'] } });
				return `${html}<br>`;
			});
		}
	}, [formulario]);

	useEffect(() => {
		const buildTermo = async () => {
			/**
			 * termo de responsabilidade do formulario da UAP
			 */
			if (formulario?.idTipoFormulario === TIPO_FORMULARIO_APROVACAO_PROJETOS) {
				const documentosDados = obtemDadosDocumento(
					formulario,
					EXTENSAO_DOC_ESPECIFICO_APROVACAO_PROJETOS,
					false,
					false
				);
				let adendo = '';
				if (documentosDados?.dados?.infosProjeto?.atividadeSaudePossuiVagaAmbulancia === 'sim') {
					adendo =
						'DECLARO QUE o projeto atende ao mínimo de 1 vaga especial no interior do lote para Embarque/Desembarque com dimensões para ambulância.';
				}
				const textoTermo =
					size(adendo) > 0 ? `<div style="font-size:16px;margin-bottom: 5px;font-weight: bold">${adendo}</div>` : '';

				return textoTermo;
			}

			/**
			 * termo de responsabilidade do licenciamento expresso dinâmico
			 */
			if (formulario?.idTipoFormulario === TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO) {
				const documentoDados = obtemFormularioLicenciamentoExpressoDados(formulario);
				const dadosRestricoes = get(documentoDados, 'dados.restricoesAdministrativas', documentoDados.dados);

				const { UNIFAMILIAR_AUTONOMA } = LE_Enquadramentos;
				// obtém tipo de formulário pelo enquadramento
				const enquadramentoDefinicao = get(formulario, 'formData.data.enquadramento.definicao', null);
				let tipoDeFormulario = null;
				if (enquadramentoDefinicao && TODOS_ENQUADRAMENTOS.includes(enquadramentoDefinicao)) {
					tipoDeFormulario = enquadramentoDefinicao;
				} else {
					// TODO: remover condicional ao padronizar 'enquadramento.definicao'
					const enquadramento = get(formulario, 'formData.data.enquadramento.respostas.1', '11');
					const listaEnquadramentos = [
						{ definicao: LE_Enquadramentos.UNIFAMILIAR_AUTONOMA, ids: ['11', '1-11'] },
						{ definicao: LE_Enquadramentos.UNIFAMILIAR_01_ECONO, ids: ['12', '1-12'] },
						{ definicao: LE_Enquadramentos.UNIFAMILIAR_02_ECONO, ids: ['13', '1-13'] },
						{ definicao: LE_Enquadramentos.UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES, ids: ['14', '1-14'] },
						{ definicao: LE_Enquadramentos.NAO_RESIDENCIAL, ids: ['15', '1-15'] },
						{ definicao: LE_Enquadramentos.CONDOMINIO_10UA, ids: ['16', '1-16'] },
						{ definicao: 'le7', ids: ['17', '1-17'] }
					];

					tipoDeFormulario = listaEnquadramentos.find(e => e.ids.includes(enquadramento))?.definicao;
				}

				let textoTermo = '<div style="color:rgb(58,69,72);font-size:16px;font-family:Lato, sans-serif;">';

				if (tipoDeFormulario === UNIFAMILIAR_AUTONOMA) {
					textoTermo = `${textoTermo}<p style="margin-bottom: 5px"><b>A liberação do presente documento não dispensa o atendimento da legislação federal que regulamenta condomínios.</b></p>`;
				}

				const rtermo = await termoRestricoes(dadosRestricoes, 'licenciamento_expresso-restricoes-administrativas');
				if (rtermo) {
					textoTermo = `${textoTermo}${rtermo}`;
				}

				textoTermo = `${textoTermo} </div>`;
				return textoTermo;
			}
		};

		if (!isTermoResponsabilidadeSetado) {
			buildTermo().then(texto => {
				if (texto) {
					setTermoResponsabilidade(termoResponsabilidadeProps => {
						const novoTexto = `${termoResponsabilidadeProps} ${texto}`;

						const saida = sanitizeHtml(novoTexto, {
							allowedAttributes: { '*': ['class', 'style'] }
						});

						//console.log('✌️novoTexto --->', novoTexto);
						return saida;
					});
					setIsTermoResponsabilidadeSetado(true);
				}
			});
		}
	}, [formulario, isTermoResponsabilidadeSetado, termoResponsabilidadeProps]);

	const AceitarCheck = (
		<div id="concordo" className="form-check m-3">
			<CheckboxSubfield
				labelStyle={{ textAlign: 'justify', paddingRight: '0' }}
				label={aceitarLabel}
				fieldName="termoResponsabilidade"
				value={!!concordo || false}
				readOnly={readOnly}
				onChangeHandler={({ value }) => onAceitarHandler && onAceitarHandler(value, { termoResponsabilidade })}
			/>
		</div>
	);

	return (
		<>
			<Declaracao showDeclaracao={showDeclaracao} />

			{[TIPO_FORMULARIO_CERTIFICACAO_SUSTENTAVEL].includes(
				get(formulario, 'formData.dadosFormulario.idTipoFormulario')
			) && (
				<TermoResponsabilidadeCertificacaoSustentavel
					enquadramento={get(formulario, 'formData.data.enquadramento.definicao')}
				/>
			)}

			{size(termoNotificacao) > 0 && (
				<div style={{ marginTop: '10px', marginBottom: '10px' }}>
					<div dangerouslySetInnerHTML={{ __html: termoNotificacao }} />
				</div>
			)}

			<TermoResp idTipoFormulario={formulario?.idTipoFormulario} termoResponsabilidade={termoResponsabilidade} />

			{[TIPO_FORMULARIO_APROVACAO_PROJETOS, TIPO_FORMULARIO_HABITE_SE, TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO].includes(
				get(formulario, 'formData.dadosFormulario.idTipoFormulario')
			) && (
				<div style={{ marginTop: '10px', marginBottom: '10px' }}>
					<i>
						<strong>
							Qualquer ato de licenciamento urbanístico-edilício não prejulga questões de domínio do requerente ou
							prejudica direitos de terceiros. A cognição da Administração Municipal é restrita ao cumprimento das
							normas locais de uso e ocupação do solo.
						</strong>
					</i>
				</div>
			)}

			{showAceitar ? AceitarCheck : undefined}
			{size(errors) > 0 && <ErrorMessages errorList={get(errors, 'termos', false)} />}
			<button
				style={{ marginTop: '10px' }}
				type="button"
				className="btn btn-primary"
				onClick={e => onAvancarHandler && onAvancarHandler(e)}
				disabled={showAceitar ? !concordo : false}
			>
				{avancarLabel}
			</button>
		</>
	);
};

TermosCondicoes.displayName = 'TermosCondicoes';
TermosCondicoes.propTypes = {
	showDeclaracao: PropTypes.bool,
	showAceitar: PropTypes.bool,
	aceitarLabel: PropTypes.string,
	avancarLabel: PropTypes.string,
	readOnly: PropTypes.bool,
	concordo: PropTypes.bool,
	termo: PropTypes.string,
	termoNotificacao: PropTypes.string,
	idTipoFormulario: PropTypes.string,
	onAceitarHandler: PropTypes.func,
	onAvancarHandler: PropTypes.func
};

export default TermosCondicoes;

const Declaracao = ({ showDeclaracao }) => {
	Declaracao.propTypes = {
		showDeclaracao: PropTypes.bool
	};
	return showDeclaracao ? (
		<div style={{ textAlign: 'justify' }}>
			<h1>Termo de Notificação</h1>
			<p>
				O usuário externo (requerente) do Portal de Licenciamento da Prefeitura Municipal de Porto Alegre, através do
				presente termo, fica ciente que:
			</p>
			<p>
				1. O protocolo digital importa na aceitação dos termos e condições que regem os Decretos:{' '}
				<b>18.623/2014 (que uniformiza os procedimentos do Processo Administrativo)</b>, 18.916/2015 (Processo
				Administrativo Eletrônico - SEI) e 20.606/2020 (no qual estabelece o Sistema de Licenciamento Digital no
				Escritório de Licenciamento) no âmbito do Município de Porto Alegre.
			</p>
			<p>
				2. Presume-se a autenticidade e veracidade de toda e qualquer informação prestada e documentação apresentada
				pelo requerente, não o eximindo da responsabilidade administrativa, civil e/ou criminal por atos praticados em
				dissonância com a legislação atual vigente ou com os procedimentos desta Prefeitura Municipal.
			</p>
			<p>
				3. É de exclusiva responsabilidade do requerente a preservação dos documentos originais apresentados, em meio
				físico, e dos documentos digitalizados até que decaia o direito de revisão dos atos praticados.
			</p>
			<p>
				4. Caso solicitado, os documentos originais deverão ser apresentados, respeitando os prazos e condições
				estabelecidos, para qualquer espécie de conferência. A validade, bem como a veracidade, do presente Termo de
				Recebimento e Responsabilidade, assim como do processo eletrônico, pode ser conferida através do seguinte
				endereço: <a href="https://licenciamento.procempa.com.br/">https://licenciamento.procempa.com.br/</a>
			</p>
		</div>
	) : null;
};
Declaracao.displayName = 'Declaracao';
Declaracao.propTypes = {};

const TermoResp = ({ idTipoFormulario, termoResponsabilidade }) =>
	size(termoResponsabilidade) > 0 ? (
		<>
			{!(idTipoFormulario === TIPO_FORMULARIO_LICENCIAMENTO_EXPRESSO) && <h1>Termo de Responsabilidade</h1>}
			<div style={{ margin: '10px 0 10px 0', maxHeight: '35rem', overflowY: 'auto' }}>
				<div dangerouslySetInnerHTML={{ __html: termoResponsabilidade }} />
			</div>
		</>
	) : null;
TermoResp.displayName = 'TermoResp';
TermoResp.propTypes = {
	idTipoFormulario: PropTypes.string,
	termoResponsabilidade: PropTypes.string
};

const TermoResponsabilidadeCertificacaoSustentavel = enquadramento =>
	// Este HTML está replicado dentro do template do documento zero (no Galgo)
	enquadramento === 'executado' ? (
		<div style={{ marginTop: '10px', marginBottom: '10px' }}>
			<p>COMO RESPONSÁVEL TÉCNICO PELA EXECUÇÃO DOS ITENS ELENCADOS NO ANEXO I (QUADRO DE PONTUAÇÃO), DECLARO:</p>
			<i>
				<strong>
					<ol>
						<li>
							Que, sob as penas da lei, executei as obras correspondentes às ações de sustentabilidade na forma definida
							no Decreto 21.789/2022, de acordo com a pontuação elencada no Anexo I;
						</li>
						<li>
							Que toda a documentação comprobatória do atendimento das ações de sustentabilidade são verdadeiras,
							estando ciente que estarei sujeito a responder civil, criminal e administrativamente em caso de
							informações inverídicas;
						</li>
						<li>
							Estar ciente de que a descaracterização das ações e práticas de sustentabilidade que justificaram a
							concessão da Certificação importará no cancelamento, a qualquer tempo, da certificação emitida, bem como
							de seus benefícios, conforme disposto no Decreto 21.789/2022;
						</li>
						<li>
							Responder civil e penalmente pela veracidade das declarações ora prestadas, isentando a prefeitura de
							Porto Alegre de quaisquer responsabilidades pelas mesmas e assumindo todas as obrigações previstas na Lei
							Complementar 284/1992 e nos Decretos nº 19.741/2017 e nº 21.393/2022, inclusive eventuais danos causados a
							terceiros.
						</li>
					</ol>
				</strong>
			</i>
		</div>
	) : enquadramento === 'proposto' ? (
		<div style={{ marginTop: '10px', marginBottom: '10px' }}>
			<p>
				COMO RESPONSÁVEL TÉCNICO PELA PROPOSIÇÃO NO PROJETO DOS ITENS ELENCADOS NO ANEXO I (QUADRO DE PONTUAÇÃO),
				DECLARO:
			</p>
			<i>
				<strong>
					<ol>
						<li>
							Que está sendo previsto no projeto do empreendimento as ações de sustentabilidade na forma definida no
							Decreto 21.789/2022, de acordo com a pontuação elencada no Anexo I;
						</li>
						<li>
							Estar ciente de que, caso se constate, a qualquer momento, desconformidade em relação aos parâmetros
							legais determinados por Lei Federal, Estadual ou Municipal específica, a edificação não poderá ser
							regularizada ou beneficiada por qualquer outra lei de anistia que venha a ser publicada, ficando sujeita
							às penalidades aplicáveis, inclusive ação demolitória, além de perder o Certificado obtido.
						</li>
						<li>
							Que são verdadeiras as informações ora prestadas, bem como estarei sujeito a responder civil, criminal e
							administrativamente em caso de informações inverídicas ou descumprimento da lei;
						</li>
						<li>
							Estar ciente do disposto no Art. 13 do Decreto nº 19.741/2017, que define a priorização de tramitação de
							licenciamento das edificações com premissas sustentáveis (Certificação Prata ou superior).
						</li>
					</ol>
				</strong>
			</i>
		</div>
	) : null;
TermoResponsabilidadeCertificacaoSustentavel.displayName = 'TermoResponsabilidadeCertificacaoSustentavel';
TermoResponsabilidadeCertificacaoSustentavel.propTypes = {
	enquadramento: PropTypes.string
};
