import React, { useState, useEffect, useMemo } from 'react';
import { useCallback } from 'react';

import PropTypes from 'prop-types';

import { useQuery } from 'react-apollo';

import useAxios from 'axios-hooks';
import gql from 'graphql-tag';
import { get, size, pick, omit, camelCase } from 'lodash';
import moment from 'moment';
import uuid from 'uuid/v4';

import Loader from 'components/Loader';
import ShowDebug from 'components/ShowDebug';
import withMessage from 'components/WithMessage';

import { EXPEDIENTES_API_URL } from 'environments';

import { accessApi } from 'utils/injectApi';
import { isDebug } from 'utils/tools';

import Question from './question';
import SelecaoEtapa from './selecao-etapa';

const debug = false && isDebug;

let i = 0;

const debugLog = (...args) => debug && console.debug('(trk-ENQUADRAMENTO-FORM)', ++i, ...args);

const EnquadramentoForm = ({
	root: propsRootOriginal,
	idExpedienteUnico,
	idMetadata,
	etapas: propsEtapas,
	readOnly,
	onSubmit,
	createMessage,
	data: propsData,
	somenteListaDocs,
	children
}) => {
	const [propsRoot, setPropsRoot] = useState(propsRootOriginal);
	const [pilha, setPilha] = useState(null);
	const [root, setRoot] = useState(null);
	const [rootPathId, setRootPathId] = useState(null);
	const [loading, setLoading] = useState(false);
	const [question, setQuestion] = useState(null);
	const [listas, setListas] = useState([]);
	const [result, setResult] = useState({});
	const [etapas, setEtapas] = useState([]);
	const [dataPromocao, setDataPromocao] = useState(null);
	const [listaDocs, setListaDocs] = useState(null);

	const usePathId = true; //!isProd;

	useEffect(() => {
		if (result) {
			debugLog('[result]', result);
		}
	}, [result]);

	const {
		data: dataListas,
		loading: loadingListas,
		error: errorListas
	} = useQuery(
		gql`
			query listasDocumentosList($term: String, $skip: Int, $limit: Int) {
				list: LicenciamentoListasDocumentosList(term: $term, skip: $skip, limit: $limit) {
					id
					documentos
				}
			}
		`,
		{
			variables: {
				limit: 2000
			},
			fetchPolicy: 'network-only'
		}
	);
	const [{ data: euData, loading: euLoading, error: euError }, execute] = useAxios(
		`${EXPEDIENTES_API_URL}/eu/${idExpedienteUnico}?somente-etapas=true`,
		{
			manual: true
		}
	);

	const carregaMetadata = useCallback(
		async recarrega => {
			debugLog('<carregaMetadata> - propsData', propsData);
			setLoading(true);
			try {
				let questionAux = null;
				if (propsData && !recarrega) {
					debugLog(
						'<carregaMetadata> vou aproveitar pré-gravado no formData.data.enquadramento.metadados: ',
						propsData.questions[0]
					);
					questionAux = propsData.questions[0];
					setLoading(false);
					setDataPromocao(propsData.dataPromocao);
				} else {
					debugLog('<carregaMetadata> vou ter que carregar metadados do banco');
					const retorno = (await accessApi(`/collections/metadata/${idMetadata}`))?.data;
					questionAux = retorno || {};
					questionAux = questionAux.questions[0];
					setDataPromocao(retorno.updatedAt);
					setPilha([questionAux]);
					if (recarrega) {
						setPropsRoot(null);
						setRoot(null);
						setRootPathId(null);
					}
					setLoading(false);
				}
				setQuestion(questionAux);
			} catch (e) {
				setQuestion([]);
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		[idMetadata, propsData]
	);

	const obtainResultPathAnswer = (p, rootPathId, rootPathLevel) =>
		(
			(p.questions || []).find(q => {
				const questionPathLevel = q.pathId.split('-').length;
				return (rootPathId.startsWith(q.pathId) && rootPathLevel !== questionPathLevel) || rootPathId === q.pathId;
			}) || {}
		).pathId;

	const obtainResult = useCallback(
		(pilha, root, rootPathId) => {
			debugLog('<obtainResult>', pilha, root);
			let extrasObject = {
				etapas: [],
				documentos: [],
				definicao: null,
				codOcorrencia: null,
				unnecessary: null,
				respostaSelecionadaNum: usePathId && rootPathId ? rootPathId : root
			};
			let respostas = {};
			let nomeLista = null;
			let nomeListaGuardado = null;
			let hasExtras = false;
			const rootPathLevel = usePathId && rootPathId ? rootPathId.split('-').length : null;

			pilha.forEach(p => {
				if (p.definicao) {
					extrasObject.definicao = (extrasObject.definicao ? `${extrasObject.definicao},` : '') + p.definicao;
				}
				if (p.codOcorrencia) {
					extrasObject.codOcorrencia =
						(extrasObject.codOcorrencia ? `${extrasObject.codOcorrencia},` : '') + p.codOcorrencia;
				}
				if (p.extra) {
					const [categoria, especificacao] = p.extra.split('.');
					let temp = [];
					switch (categoria) {
						case 'etapa':
							extrasObject.etapas = [...extrasObject.etapas, especificacao];
							break;
						case 'documento':
							extrasObject.documentos = [...extrasObject.documentos, especificacao];
							break;
						case 'unnecessary':
							hasExtras = size(especificacao) > 0 ? true : hasExtras;
							extrasObject.unnecessary = especificacao;
							break;
						default:
							temp = get(extrasObject, [categoria], []);
							extrasObject[categoria] = [...temp, especificacao];
							break;
					}
				}
				if (size(p.questions) > 0) {
					nomeListaGuardado = p.value || nomeListaGuardado;
					if (usePathId && p.pathId) {
						respostas[p.pathId] = obtainResultPathAnswer(p, rootPathId, rootPathLevel);
					} else {
						respostas[p.id] = ((p.questions || []).find(q => root.startsWith(q.id)) || {}).id;
					}
				} else {
					nomeLista = p.value || nomeListaGuardado;
					if (usePathId && p.pathId) {
						respostas[p.pathId] = nomeLista ? nomeLista : obtainResultPathAnswer(p, rootPathId, rootPathLevel);
					} else {
						respostas[p.id] = nomeLista ? nomeLista : ((p.questions || []).find(q => root.startsWith(q.id)) || {}).id;
					}
				}
			});

			const resultAux = {
				nomeLista,
				respostas,
				extrasObject,
				etapas: pick(propsEtapas || {}, extrasObject.etapas || []),
				dataPromocao
			};
			return resultAux;
			// return nomeLista || hasExtras ? resultAux : null;
		},
		[dataPromocao, propsEtapas, usePathId]
	);

	useEffect(() => {
		debugLog('[carregaMetadata]');
		// Carrega as questões do enquadramento
		carregaMetadata();
	}, [carregaMetadata]);

	useEffect(() => {
		if (dataListas) {
			debugLog('[dataListas]', dataListas);
			setListas(dataListas.list.map(lista => lista.id));
			setListaDocs(dataListas.list.reduce((acc, lista) => ({ ...acc, [lista.id]: lista.documentos || [] }), {}));
		}
	}, [dataListas]);

	useEffect(() => {
		if (errorListas) {
			debugLog('[errorListas]', errorListas);
			console.error('Erro ao acessar a relação de listas de documentos para enquadramento', errorListas);
		}
	}, [errorListas]);

	useEffect(() => {
		setLoading(false);
		if (size(question) > 0 && size(listas) > 0 && !root) {
			debugLog('[root, listas, question]: ', root, listas, question);

			let novaPilha = [];

			const avalia = q => {
				if (
					usePathId && q?.pathId
						? propsRoot.startsWith(`${q.pathId}-`) || propsRoot === q.pathId
						: propsRoot.startsWith(q.id)
				) {
					novaPilha.push(q);
				}
				if (size(q.questions) > 0) {
					q.questions.forEach(q1 => avalia(q1));
				}
			};

			if (propsRoot) {
				avalia(question);
				if (size(novaPilha) > 0) {
					const ultimo = novaPilha[novaPilha.length - 1];
					if (usePathId && ultimo.pathId ? ultimo.pathId === propsRoot : ultimo.id === propsRoot) {
						setPilha(novaPilha);
						setRoot(propsRoot);
						setRootPathId(ultimo.pathId ? propsRoot : null);
						setResult(obtainResult(novaPilha, propsRoot, propsRoot));
					} else {
						createMessage(
							`A resposta gravada não pode ser recuperada. Você pode responder novamente este questionário ou entrar em contato informando o seguinte código: ${propsRoot}`
						);
						setPilha([question]);
						setRoot(question.id);
						setRootPathId(question.pathId);
					}
				}
			} else {
				setPilha([question]);
				setRoot(question.id);
				setRootPathId(question.pathId);
			}
		}
	}, [createMessage, listas, obtainResult, pilha, propsRoot, question, root, usePathId]);

	useEffect(() => {
		if (((usePathId && rootPathId) || root) && size(pilha) > 0) {
			debugLog('[root, pilha]', root, pilha);

			const ultimo = pilha[pilha.length - 1];
			const ultimoPathLevel = ultimo.pathId ? ultimo.pathId.split('-').length : null;
			const rootPathLevel = rootPathId ? rootPathId.split('-').length : null;

			if (usePathId && rootPathId ? ultimoPathLevel > rootPathLevel : parseInt(ultimo.id) > parseInt(root)) {
				// clicou mais pra trás na hierarquia (não clicou no último da pilha)
				const pilhaAux = pilha.filter(p => size(p.id) < size(root));
				setPilha(pilhaAux);
				setResult(null);
			} else {
				if (usePathId && rootPathId ? rootPathId !== ultimo.pathId : root !== ultimo.id) {
					let hasUnnecessary = size(ultimo.extra) > 0 && ultimo.extra.split('.')[0] === 'unnecessary';
					// clicou no último da pilha e último ainda não é o root (a igualdade acontece na carga)
					if (size(ultimo.questions) === 0 || hasUnnecessary) {
						let pilhaAux = [...pilha.slice(0, pilha.length - 1)];
						const ultimo2 = pilhaAux[pilhaAux.length - 1];
						const novo = ultimo2.questions.find(q =>
							usePathId && rootPathId ? q.pathId === rootPathId : q.id === root
						);
						pilhaAux = [...pilhaAux, novo];
						setPilha(pilhaAux);
						//bateu no final da trilha
						setResult(obtainResult(pilhaAux, root, rootPathId));
					} else if (size(ultimo.questions) > 0) {
						setResult(null);
						// a princípio ou tem value ou tem questions, então não pŕecisaria do if (ao lado do else)
						const selecionado = ultimo.questions.find(q =>
							usePathId && q.pathId ? q.pathId === rootPathId : q.id === root
						);
						if (selecionado) {
							// clicou no último grupo e tem mais questoes
							const pilhaAux = [...pilha, selecionado];
							setPilha(pilhaAux);
						} else {
							const pilhaAux = pilha.filter(p => p.id !== ultimo.id);
							setPilha(pilhaAux);
						}
					}
				} else {
					let hasUnnecessary = size(ultimo.extra) > 0 && ultimo.extra.split('.')[0] === 'unnecessary';
					if (size(ultimo.questions) === 0 || hasUnnecessary) {
						setResult(obtainResult(pilha, root, rootPathId));
					} else {
						setResult(null);
					}
				}
			}
		}
	}, [pilha, root, obtainResult, rootPathId, usePathId]);

	useEffect(() => {
		if (idExpedienteUnico && size(etapas) === 0 && size(get(result, 'extrasObject.etapas')) > 0) {
			debugLog('[idExpedienteUnico, etapas, result]: ', idExpedienteUnico, etapas, result);
			execute();
		}
	}, [idExpedienteUnico, result, etapas, execute]);

	useEffect(() => {
		if (size(euData) > 0) {
			debugLog('[euData]: ', euData);
			setEtapas(euData || []);
		}
	}, [euData]);

	useEffect(() => {
		if (euError) {
			debugLog('[euError]: ', euError);
			createMessage('Problemas para obter as etapas do Expediente Único. Tente novamente mais tarde', 5);
		}
	}, [createMessage, euError]);

	useEffect(() => {
		debugLog(['euLoading'], euLoading);
	}, [euLoading]);

	const onChangeHandler = useCallback(question => {
		if (question.id) {
			setRoot(question.id);
			setRootPathId(question.pathId);
		}
	}, []);

	useEffect(() => {}, [euLoading]);

	useEffect(() => {}, [loadingListas]);

	useEffect(() => {}, [loading]);

	const isFormularioCompleto = useMemo(() => {
		let saida = true;
		saida = !get(result, 'extrasObject.unnecessary'); // não pode ter bloqueio
		const etapasCompletas = (get(result, 'extrasObject.etapas') || []).reduce((acc, etapa) => {
			const satisfaz = size(get(result, `etapas.${etapa}`)) > 0; // preenchido
			return acc && satisfaz;
		}, true);
		saida = saida && etapasCompletas;
		return saida;
	}, [result]);

	const recarregarEnquadramento = () => {
		carregaMetadata(true);
	};

	const todosDocumentos = useMemo(() => {
		let lista1 = [];
		if (result?.nomeLista && size(listaDocs?.[result.nomeLista]) > 0) {
			lista1 = listaDocs[result.nomeLista];
		}
		let lista2 = [];
		if (get(result, 'extrasObject.documentos')) {
			lista2 = result.extrasObject.documentos.map(docName => ({ tituloDocumento: docName, obrigatorio: true }));
		}
		let listaTotal = [...lista1, ...lista2];
		return listaTotal;
	}, [listaDocs, result]);

	return pilha ? (
		<div className={`enquadramento-container${somenteListaDocs ? ' somente-docs' : ''}`}>
			{(loading || loadingListas || euLoading) && <Loader msg="Carregando questões do enquadramento" />}
			<div className="enquadramento-questions">
				{pilha.map(question => (
					<Question
						key={uuid()}
						question={question}
						root={root}
						rootPathId={rootPathId}
						readOnly={readOnly}
						onChange={onChangeHandler}
						debug={isDebug}
					/>
				))}
			</div>
			{size(result) > 0 && (
				<div className="enquadramento-result">
					{size(get(result, 'extrasObject.etapas')) > 0 &&
						!somenteListaDocs &&
						result.extrasObject.etapas.map(tipoEtapa => (
							<SelecaoEtapa
								key={tipoEtapa}
								tipoEtapa={tipoEtapa}
								selected={get(result, `etapas.${tipoEtapa}`)}
								etapas={etapas}
								readOnly={readOnly}
								onChange={item =>
									item
										? setResult({ ...result, etapas: { ...(result.etapas || {}), [tipoEtapa]: item } })
										: setResult({ ...result, etapas: { ...omit(result.etapas || {}, [tipoEtapa]) } })
								}
							/>
						))}
					{size(get(result, 'extrasObject.unnecessary')) > 0 && <h2>{get(result, 'extrasObject.unnecessary')}</h2>}
					{somenteListaDocs && (
						<div className="enquadramento-docs">
							{size(todosDocumentos) > 0 ? (
								<>
									<h3>Lista de documentos</h3>
									<ul>
										{todosDocumentos.map(documento => (
											<li key={uuid()}>
												{documento.tituloDocumento || 'Título do documento não definido'}
												{documento.obrigatorio ? (
													<sup style={{ fontSize: '15pt', color: 'red', verticalAlign: 'text-bottom' }}>*</sup>
												) : (
													''
												)}
											</li>
										))}
									</ul>
								</>
							) : (
								<h3>Não há documentos relacionados ao enquadramento selecionado</h3>
							)}
							<h1>Documentos Fixos</h1>
							{children}
						</div>
					)}
					{!readOnly && onSubmit && (
						<div className="toolbar toolbar-right">
							{isFormularioCompleto && (
								<button
									type="button"
									className="btn btn-primary"
									onClick={() => onSubmit({ ...result, metadados: question })}
									disabled={size(get(result, 'extrasObject.unnecessary')) > 0}
								>
									Enviar
								</button>
							)}
						</div>
					)}
					{isDebug && !somenteListaDocs && (
						<Debug
							result={result}
							listaNaoCadastrada={!result.nomeLista || !(listas || []).find(l => l === result.nomeLista)}
							recarregarEnquadramento={recarregarEnquadramento}
							readOnly={readOnly}
							metadados={question}
						/>
					)}
				</div>
			)}
			<ShowDebug data={{ 1: root, 2: pilha.map(i => i.id) }} />
		</div>
	) : null;
};
EnquadramentoForm.displayName = 'EnquadramentoForm';
EnquadramentoForm.propTypes = {
	root: PropTypes.string,
	idExpedienteUnico: PropTypes.string,
	idMetadata: PropTypes.string,
	etapas: PropTypes.object,
	readOnly: PropTypes.bool,
	onSubmit: PropTypes.func,
	createMessage: PropTypes.func,
	data: PropTypes.object,
	somenteListaDocs: PropTypes.bool,
	children: PropTypes.node
};

export default withMessage(EnquadramentoForm);

const Debug = ({ result, listaNaoCadastrada, recarregarEnquadramento, readOnly }) => (
	<div className="enquadramento-debug">
		<h2>Resultado (apresentados apenas em debug)</h2>
		<ul>
			<li>
				<span style={{ display: 'flex', justifyContent: 'space-between' }}>
					<span>Nome da lista de documentos: {result.nomeLista}</span>
					{listaNaoCadastrada && (
						<span style={{ fontSize: 'small', fontWeight: 'bold', color: '#fa970f' }}>lista não cadastrada</span>
					)}
				</span>
			</li>
			{get(result, 'extrasObject.documentos') && (
				<li>
					Outros Documentos
					<ul>
						{result.extrasObject.documentos.map(documento => (
							<li key={camelCase(documento)}>{documento}</li>
						))}
					</ul>
				</li>
			)}
			{get(result, 'extrasObject.etapas') && (
				<li>
					Etapas
					<ul>
						{result.extrasObject.etapas.map(etapa => (
							<li key={etapa}>
								{etapa}: {(get(result, `etapas.${etapa}`) || {}).display}
							</li>
						))}
					</ul>
				</li>
			)}
			{get(result, 'extrasObject.definicao') && <li>Definição: {result.extrasObject.definicao}</li>}
			{get(result, 'dataPromocao') && (
				<li>Data de Promoção: {moment(result.dataPromocao).format('DD/MM/YYYY HH:mm:ss')}</li>
			)}
			{get(result, 'extrasObject.codOcorrencia') && <li>Código de Ocorrência: {result.extrasObject.codOcorrencia}</li>}
			{size(
				Object.keys(
					omit(get(result, 'extrasObject') || {}, ['etapas', 'documentos', 'definicao', 'codOcorrencia', 'unnecessary'])
				)
			) > 0 && (
				<li>
					ExtraData:{' '}
					<pre>
						{JSON.stringify(
							omit(get(result, 'extrasObject') || {}, [
								'etapas',
								'documentos',
								'definicao',
								'codOcorrencia',
								'unnecessary'
							]),
							null,
							2
						)}
					</pre>
				</li>
			)}
			<li>
				Respostas do formulário:<pre>{JSON.stringify(result.respostas, null, 2)}</pre>
			</li>
		</ul>
		{!readOnly && (
			<button type="button" onClick={recarregarEnquadramento}>
				Recarregar metadados do Enquadramento
			</button>
		)}
	</div>
);
Debug.displayName = 'Debug';
Debug.propTypes = {
	result: PropTypes.object,
	listaNaoCadastrada: PropTypes.bool,
	recarregarEnquadramento: PropTypes.func,
	readOnly: PropTypes.bool
};
