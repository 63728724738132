import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { Modal } from 'react-bootstrap';

const TemplateRenderer = ({
	title,
	openLabel = 'Abrir',
	closeLabel = 'Fechar',
	templatePath,
	dataEndpoint,
	children
}) => {
	const [template, setTemplate] = useState('');
	const [data, setData] = useState(null);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		// Fetch the template
		fetch(templatePath)
			.then(response => response.text())
			.then(html => setTemplate(html))
			.catch(error => console.error('Error fetching template:', error));

		if (dataEndpoint) {
			// Fetch the data
			fetch(dataEndpoint)
				.then(response => response.json())
				.then(jsonData => setData(jsonData))
				.catch(error => console.error('Error fetching data:', error));
		}
	}, [templatePath, dataEndpoint]);

	const renderTemplate = () => {
		if (!template) return null;

		// Replace placeholders in the template with data
		return template.replace(/\{\{(.*?)\}\}/g, (_, key) => data[key.trim()] || '');
	};

	return (
		<>
			{(
				<span
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						setShowModal(true);
					}}
				>
					{children}
				</span>
			) || (
				<button type="button" className="btn btn-secondary" onClick={() => setShowModal(true)}>
					{openLabel}
				</button>
			)}
			<Modal show={showModal} onHide={() => setShowModal(false)} aria-labelledby="Arrazoado EVU">
				{title && (
					<Modal.Header closeButton>
						<Modal.Title id="arrazoadoEvu">{title}</Modal.Title>
					</Modal.Header>
				)}
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: renderTemplate() }} />
				</Modal.Body>
				<Modal.Footer>
					<button type="button" className="btn btn-default" onClick={() => setShowModal(false)}>
						{closeLabel}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
TemplateRenderer.displayName = 'TemplateRenderer';
TemplateRenderer.propTypes = {
	title: PropTypes.string,
	openLabel: PropTypes.string,
	closeLabel: PropTypes.string,
	templatePath: PropTypes.string.isRequired,
	dataEndpoint: PropTypes.string,
	children: PropTypes.node
};
export default TemplateRenderer;
