import React from 'react';

import PropTypes from 'prop-types';

const CLASS_NAME_FORM_CONTROL = 'form-control';

const ObsField = ({ title, observacao, name, containerClass = CLASS_NAME_FORM_CONTROL }) => (
	<div className={containerClass}>
		<div className="col-md-12" name={name}>
			{title && <label className="control-label">{title}</label>}
			<div style={{ whiteSpace: 'pre-wrap', textAlign: 'justify' }}>{observacao}</div>
		</div>
	</div>
);
ObsField.displayName = 'ObsField';

ObsField.propTypes = {
	name: PropTypes.string,
	title: PropTypes.string,
	observacao: PropTypes.string,
	containerClass: PropTypes.string
};

export default ObsField;
