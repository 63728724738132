import { get } from 'lodash';

import { LE_Enquadramentos } from './MetadataLE';

/**
 * Limpa campos ocultos/não utilizados.
 *
 * @param {object} data
 * @param {string} tipoForm
 *
 * @returns {object} Dados corrigidos
 */
export function limparForm(data, tipoForm) {
	let formdados = { ...data };
	const restricoesData = get(data, 'restricoesAdministrativas', data);

	const formUnifamiliarAutonoma = tipoForm === LE_Enquadramentos.UNIFAMILIAR_AUTONOMA;
	const formUnifamiliarUmaOuDuasEconomias = [
		LE_Enquadramentos.UNIFAMILIAR_01_ECONO,
		LE_Enquadramentos.UNIFAMILIAR_02_ECONO
	].includes(tipoForm);

	const delEdificacaoUltrapassaAlturaDECEA = formdados.dmiRestricaoAlturaProximidadeAeroporto === 'nao';
	const delRestricaoAreaNaoEdificavel = formdados.dmiRestricaoPluvial === 'nao';
	const delPrevistoConstrucaoAreaNaoEdificavel = formdados.restricaoAreaNaoEdificavel === 'nao';
	const delQuotaALP = get(restricoesData, 'restricaoALPdoCondominio') === 'nao';
	const delRestricaoTracadoViarioPDDUA = formdados.restricaoTracadoViarioPDDUA === 'nao';
	const delDmiRestricaoAPP = formdados.dmiRestricaoAPP === 'nao';
	const delDmiRestricaoAreaVerde = formdados.dmiRestricaoAreaVerde === 'nao';
	const delDmiRestricaoAreaEscolar = formdados.dmiRestricaoAreaEscolar === 'nao';

	const delPredioTombadoInventariado = formdados.predioTombadoInventariado === 'nao';
	const delPossuiEvuTombado = formdados.classificacaoEdificacao === 'tombado';
	const delPossuiEvuEstruturacao = formdados.classificacaoEdificacao === 'estruturacao';
	const delTerrenoPossuiRuDefinido = formdados.classificacaoEdificacao === 'compatibilizacao';
	const delPossuiEvuCompatibilizacao = formdados.terrenoPossuiRuDefinido === 'nao';

	const delAreaDemolir = formdados.infosProjeto?.projetoNovoOuAumento === 'novo';

	if (delPossuiEvuTombado) {
		delete formdados.possuiEvuEstruturacao;
		delete formdados.terrenoPossuiRuDefinido;
		delete formdados.possuiEvuCompatibilizacao;
	}
	if (delPossuiEvuEstruturacao) {
		delete formdados.possuiEvuTombado;
		delete formdados.terrenoPossuiRuDefinido;
		delete formdados.possuiEvuCompatibilizacao;
	}
	if (delTerrenoPossuiRuDefinido) {
		delete formdados.possuiEvuTombado;
		delete formdados.possuiEvuEstruturacao;
	}
	if (delPossuiEvuCompatibilizacao) {
		delete formdados.possuiEvuCompatibilizacao;
	}

	if (delPredioTombadoInventariado) {
		delete formdados.classificacaoEdificacao;
		delete formdados.possuiEvuTombado;
		delete formdados.possuiEvuEstruturacao;
		delete formdados.terrenoPossuiRuDefinido;
		delete formdados.possuiEvuCompatibilizacao;
	}

	// restrições em comum
	if (delEdificacaoUltrapassaAlturaDECEA) {
		delete formdados.edificacaoUltrapassaAlturaDECEA;
	}
	if (delRestricaoAreaNaoEdificavel) {
		delete formdados.restricaoAreaNaoEdificavel;
		delete formdados.previstoConstrucaoEmAreaNaoEdificavel;
	}
	if (delPrevistoConstrucaoAreaNaoEdificavel) {
		delete formdados.previstoConstrucaoEmAreaNaoEdificavel;
		delete formdados.previstoMuroEmAreaNaoEdificavel;
	}
	if (delRestricaoTracadoViarioPDDUA) {
		delete formdados.edificacaoIncideTracadoViarioPDDUA;
		delete formdados.possuiEstudoPDDUA;
	}
	if (delDmiRestricaoAPP) {
		delete formdados.construcaoSobreAppApan;
	}
	if (delDmiRestricaoAreaVerde) {
		delete formdados.restricaoEdificacaoAreaVerde;
	}
	if (delDmiRestricaoAreaEscolar) {
		delete formdados.projetoIncideSobreEscola;
	}

	if (delAreaDemolir) {
		delete formdados.detalhesAreas?.totaisManuais?.areaTotalDemolirNaoResidencial;
		delete formdados.detalhesAreas?.totaisManuais?.areaTotalDemolirResidencial;
		delete formdados.detalhesAreas?.totaisManuais?.areaTotalDemolirUnifamiliarNaoResidencial;
	}

	// restrições específicas conforme tipo do form
	if (formUnifamiliarAutonoma) {
		if (delQuotaALP) {
			delete formdados.quotaALP;
		}
	} else if (formUnifamiliarUmaOuDuasEconomias) {
		formdados = limpaFormUnifamiliarUmaOuDuasEconomias(formdados);
		formdados = limparInfosProjeto(formdados);
	}
	formdados = limparInfosTerreno(formdados);

	// LIMPEZA DE CAMPOS FILHO DO TIPO FORMULARIO UNIFAMILIAR_ATE_02_ECONO_E_NAO_RES
	(formdados.planoDiretor?.dadosSubunidades || []).forEach(subunidade => {
		if (subunidade.possuiAlturaDivisa === 'nao' && subunidade.alturaDivisaRegimeUrbanisticoAnexo) {
			delete subunidade.alturaDivisaRegimeUrbanisticoAnexo;
		}
		if (subunidade.possuiAlturaBase === 'nao' && subunidade.alturaBaseRegimeUrbanisticoAnexo) {
			delete subunidade.alturaBaseRegimeUrbanisticoAnexo;
		}
	});
	if (formdados.infosProjeto?.projetoImpactoUrbano === 'nao' && formdados.infosProjeto?.projetoViabilidaUrbanistico) {
		delete formdados.infosProjeto.projetoViabilidaUrbanistico;
	}
	if (formdados.infosProjeto?.projetoPossuiBaseCorpo === 'nao' && formdados.infosProjeto?.totalBaseProjeto) {
		delete formdados.infosProjeto.totalBaseProjeto;
	}
	if (formdados.infosProjeto?.projetoPossuiBaseCorpo === 'nao' && formdados.infosProjeto?.totalCorpoProjeto) {
		delete formdados.infosProjeto.totalCorpoProjeto;
	}
	if (formdados.infosProjeto?.possuiVagaCargaDescarga === 'nao' && formdados.infosProjeto?.qtdVagasCargaDescarga) {
		delete formdados.infosProjeto.qtdVagasCargaDescarga;
	}

	return formdados;
}

const limparInfosTerreno = data => {
	let formdados = data;

	const possuiAreaDeMenorPoligono = formdados?.possuiAreaDeMenorPoligono === 'sim';
	const possuiAreaAtingida = formdados?.possuiAreaAtingida === 'sim';
	const possuiAreaAtingidaDeMenorPoligono = formdados?.possuiAreaAtingidaDeMenorPoligono === 'sim';

	if (!possuiAreaDeMenorPoligono) {
		delete formdados.areaMenorPoligonoLote;
		delete formdados.areaMenorPoligonoLoteMais5;
	}
	if (!possuiAreaAtingida) {
		delete formdados.areaAtingidaMatricula;
		delete formdados.areaRemanescenteMatricula;
	}

	if (!possuiAreaDeMenorPoligono || !possuiAreaAtingida) {
		delete formdados.possuiAreaAtingidaDeMenorPoligono;
		delete formdados.areaAtingidaMenorPoligonal;
		delete formdados.areaRemanescenteMenorPoligonal5;
	}

	if (!possuiAreaAtingidaDeMenorPoligono) {
		delete formdados.areaAtingidaMenorPoligonal;
		delete formdados.areaRemanescenteMenorPoligonal5;
	}

	return formdados;
};

const limpaFormUnifamiliarUmaOuDuasEconomias = data => {
	let formdados = data;

	const delEdificacaoIncideTracadoViarioPDDUA = formdados?.restricaoTracadoViarioPDDUA === 'nao';
	const delPossuiEstudoPDDUA = formdados?.edificacaoIncideTracadoViarioPDDUA === 'nao';
	const delRestricaoEdificacaoAreaVerde = formdados?.dmiRestricaoAreaVerde === 'nao';
	const delProjetoIncideSobreEscola = formdados?.dmiRestricaoAreaEscolar === 'nao';

	if (delPossuiEstudoPDDUA) {
		delete formdados.possuiEstudoPDDUA;
	}
	if (delEdificacaoIncideTracadoViarioPDDUA) {
		delete formdados.edificacaoIncideTracadoViarioPDDUA;
		delete formdados.possuiEstudoPDDUA;
	}
	if (delRestricaoEdificacaoAreaVerde) {
		delete formdados.restricaoEdificacaoAreaVerde;
	}
	if (delProjetoIncideSobreEscola) {
		delete formdados.projetoIncideSobreEscola;
	}

	return formdados;
};

const limparInfosProjeto = data => {
	let formdados = data;
	const isProjetoPossuiBaseCorpo =
		formdados.projetoPossuiBaseCorpo === 'sim' ? true : formdados.projetoPossuiBaseCorpo === 'nao' ? false : undefined;

	if (isProjetoPossuiBaseCorpo === true) {
		delete formdados.taxaOcupacaoTotal;
		delete formdados.taxaOcupacaoTotalPermitido;
	} else if (isProjetoPossuiBaseCorpo === false) {
		delete formdados.totalBaseProjeto;
		delete formdados.totalPermitidoBase;
		delete formdados.totalCorpoProjeto;
		delete formdados.totalPermitidoCorpo;
	}

	return formdados;
};
