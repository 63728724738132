import React, { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Alert } from 'react-bootstrap';

import Tippy from '@tippyjs/react';

import TooltipIcon from 'components/TooltipIcon';
import WarningMessage from 'components/WarningMessage';

import 'tippy.js/dist/tippy.css';

const RadioField = ({
	label,
	required,
	name,
	value,
	options,
	readOnly,
	disabled,
	onChangeHandler,
	radioStack,
	labelEnum,
	tooltip,
	embedded
}) => {
	const onChangeRadio = e => {
		const { value: codigo } = e.target;
		onChangeHandler({ name, value: codigo });
		onChangeHandler({ name: 'errors', value: { [name]: validate(codigo, required, label) } });
	};
	return (
		<>
			{embedded ? (
				<label className="control-label flex flex-nowrap" style={{ marginBottom: '12px' }}>
					{labelEnum || label}
					{required && <span className="required">*</span>}
					{tooltip && (
						<Tippy content={tooltip} delay={100} animation="fade">
							<i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
						</Tippy>
					)}
				</label>
			) : (
				<div className="row">
					<div className="form-group col-md-12">
						<label className="control-label">
							{labelEnum || label}
							{required && <span className="required">*</span>}
							{tooltip && (
								<Tippy content={tooltip} delay={100} animation="fade">
									<i className="fa fa-info-circle" aria-hidden="true" style={{ marginLeft: '4px' }}></i>
								</Tippy>
							)}
						</label>
					</div>
				</div>
			)}

			<div>
				{options.map(o => {
					const codigo = typeof o === 'object' ? o.codigo : o;
					const descricao = typeof o === 'object' ? o.descricao : o;
					const iconWarning = typeof o === 'object' ? o.iconWarning : o;
					const defaultValue = typeof o === 'object' ? o.defaultValue || false : false;
					if (defaultValue && !value) {
						onChangeRadio({ target: { name, value: codigo } });
					}
					return (
						<Fragment key={codigo}>
							<div className={`form-check ${radioStack ? '' : 'form-check-inline'}`}>
								<label className="form-check-label">
									<input
										type="radio"
										value={codigo}
										name={name}
										onClick={onChangeRadio}
										readOnly={readOnly}
										disabled={disabled}
										onChange={() => false}
										checked={value === codigo || (!value && o.defaultValue === true)}
										className="form-check-input"
									/>{' '}
									{iconWarning && value === codigo ? (
										<TooltipIcon
											label={descricao}
											text={iconWarning}
											icon="fa-exclamation-triangle"
											iconColor="#b39d5b"
										/>
									) : (
										descricao
									)}
								</label>
							</div>
						</Fragment>
					);
				})}
				{options.map(o => {
					const codigo = typeof o === 'object' ? o.codigo : o;
					return o.observacao && value === codigo ? (
						<Alert
							key={codigo}
							variant={o.variant || 'warning'}
							style={{ whiteSpace: 'pre-wrap', marginTop: '10px', marginLeft: '0px' }}
						>
							<b>Observação: </b>
							{o.observacao}
						</Alert>
					) : null;
				})}
				{options
					.filter(o => typeof o === 'object' && value === o.codigo && !!o.warning)
					.map(o => (
						<WarningMessage key={o.codigo} style={{ marginLeft: '26px' }}>
							{o.warning}
						</WarningMessage>
					))}
			</div>
		</>
	);
};
RadioField.displayName = 'RadioField';

RadioField.propTypes = {
	title: PropTypes.string,
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	labelEnum: PropTypes.element,
	required: PropTypes.bool,
	placeHolder: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	readOnly: PropTypes.bool,
	disabled: PropTypes.bool,
	options: PropTypes.arrayOf(PropTypes.object),
	onChangeHandler: PropTypes.func,
	isInvalid: PropTypes.bool,
	radioStack: PropTypes.bool,
	tooltip: PropTypes.string,
	embedded: PropTypes.bool
};

export default RadioField;

export const validate = (value, required, label = 'Campo') => {
	let errors = [];
	if (required) {
		if (!value) {
			errors.push(`${label} deve ser informado`);
		}
	}
	return errors;
};
