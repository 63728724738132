import { fromJS, Map, List, isImmutable } from 'immutable';
import { findIndex, filter, isNil } from 'lodash';
import { size, cloneDeep } from 'lodash';
import uuid from 'uuid/v1';

import { compareStates } from 'utils/tools';

import {
	SET_FORMULARIO,
	SET_FORM_DATA,
	SET_ENQUADRAMENTO_DATA,
	SET_RELOAD,
	SET_LOADING,
	SET_ERRORS,
	LIMPA_STORE,
	UPLOAD_SUCCESS,
	SET_RESULT,
	REMOVE_FILE_STORE,
	UPDATE_FORM_ID,
	UPDATE_FORM_DATA,
	ADD_NEW_DOCUMENT,
	RETIRA_NEW_DOCUMENT,
	SET_MOTIVO_DOC,
	SET_DATA_COMPARECIMENTO,
	TOGGLE_DOC,
	SET_FORM_DATA_DATA,
	SET_USER_AGREEMENT,
	UPDATE_STATUS_DOCUMENTO,
	UPDATE_RESPOSTA_EXTRAINFO,
	SET_BPM_TASKS,
	SET_KC_USER,
	SALVA_ANEXO_LIST,
	CLEAR_ANEXO_LIST,
	SET_PERSISTENT_STATE,
	SET_USUARIO,
	SET_USUARIO_INTERNO,
	LIMPAR_DOCUMENTOS_DADOS
} from './constants';

const initialState = fromJS({});

export default (state = initialState, action) => {
	switch (action.type) {
		case 'salvar-skip-pesquisa': {
			return state.set('skip', action.payload);
		}
		case 'salvar-filtro-pesquisa': {
			return size(action.payload) > 0 ? state.set('filtro', action.payload) : state.remove('filtro');
		}
		case SET_USER_AGREEMENT: {
			const { concordo, termoAceito } = action.payload;
			let termo = termoAceito;
			if (concordo !== true) {
				termo = undefined;
			}

			return state
				.setIn(['formulario', 'formData', 'data', 'concordo'], concordo)
				.setIn(['formulario', 'formData', 'data', 'termoResponsabilidadeAceito'], termo);
		}
		case SET_FORMULARIO:
			return state.set('formulario', fromJS(action.payload));

		case UPDATE_FORM_ID: {
			return state.setIn(['formulario', 'formData', 'id'], action.payload).set('result', 'id_substituido');
		}

		case UPDATE_FORM_DATA: {
			const { documentos, sei, bpmProcessDefinition, bpmProcessInstance, bpmUser } = action.payload;
			let newState = state;

			if (documentos) {
				newState = newState.setIn(['formulario', 'documentos'], documentos);
			}
			if (sei) {
				if (sei.idProcedimentoSei) {
					newState = newState.setIn(['formulario', 'formData', 'idProcedimentoSei'], sei.idProcedimentoSei);
				}
				if (sei.procedimentoFormatadoSei) {
					newState = newState.setIn(
						['formulario', 'formData', 'procedimentoFormatadoSei'],
						sei.procedimentoFormatadoSei
					);
				}
				if (sei.urlConsultaPublicaSei) {
					newState = newState.setIn(['formulario', 'formData', 'urlConsultaPublicaSei'], sei.urlConsultaPublicaSei);
				}
			}

			if (bpmProcessDefinition) {
				newState = newState.setIn(['formulario', 'formData', 'bpmProcessDefinition'], bpmProcessDefinition);
			}
			if (bpmProcessInstance) {
				newState = newState.setIn(['formulario', 'formData', 'bpmProcessInstance'], bpmProcessInstance);
			}
			if (bpmUser) {
				newState = newState.setIn(['formulario', 'formData', 'bpmUser'], bpmUser);
			}

			return newState;
		}

		case SET_FORM_DATA: {
			let data = action.payload;
			return state.setIn(['formulario', 'formData'], fromJS(data));
		}

		case SET_ENQUADRAMENTO_DATA: {
			const { enquadramento, documentosAtualizados } = action.payload;
			return state
				.setIn(['formulario', 'documentos'], fromJS(documentosAtualizados))
				.setIn(['formulario', 'formData', 'data', 'enquadramento'], fromJS(enquadramento));
		}

		case SET_FORM_DATA_DATA: {
			let data = action.payload;
			return state.setIn(['formulario', 'formData', 'data'], fromJS(data));
		}

		case UPLOAD_SUCCESS: {
			const doc = action.payload;
			const form = state.get('formulario');
			const formJS = form && Map.isMap(form) ? form.toJS() : form;
			const index = findIndex(formJS.documentos, item => item.id === doc.id);
			if (index < 0) {
				formJS.documentos.push(doc);
			} else {
				formJS.documentos[index].filename = doc.filename;
				formJS.documentos[index].originalName = doc.originalName;
				formJS.documentos[index].size = doc.size;
			}
			return state.set('formulario', fromJS(formJS));
		}

		case ADD_NEW_DOCUMENT: {
			const documentos = state.getIn(['formulario', 'documentos']);
			const documentosJS = documentos && List.isList(documentos) ? documentos.toJS() : documentos;
			documentosJS.push(action.payload);

			return state.setIn(['formulario', 'documentos'], fromJS(documentosJS));
		}

		case RETIRA_NEW_DOCUMENT: {
			// tratado também pelo saga para remover do banco

			const { idDocumento, idsDocumentos } = action.payload;

			const idsToRemove = idsDocumentos || [idDocumento];

			const documentos = state.getIn(['formulario', 'documentos']);
			let docsJS = documentos && isImmutable(documentos) ? documentos.toJS() : documentos;
			const documentosJS = docsJS.filter(doc => !idsToRemove.includes(doc.id));

			const documentosFormData = state.getIn(['formulario', 'formData', 'documentos']);
			let docsFormDataJS =
				documentosFormData && isImmutable(documentosFormData) ? documentosFormData.toJS() : documentosFormData;
			const documentosFormDataJS = docsFormDataJS.filter(doc => !idsToRemove.includes(doc.id));

			const newState = state
				.setIn(['formulario', 'documentos'], fromJS(documentosJS))
				.setIn(['formulario', 'formData', 'documentos'], fromJS(documentosFormDataJS));

			return newState;
		}

		case REMOVE_FILE_STORE: {
			const doc = action.payload;
			const form = state.get('formulario');
			const formJS = form && Map.isMap(form) ? form.toJS() : form;
			const index = findIndex(formJS.documentos, item => item.id === doc.id);
			if (index !== -1) {
				delete formJS.documentos[index].filename;
				delete formJS.documentos[index].originalName;
				delete formJS.documentos[index].size;
				delete formJS.documentos[index].idDocumentoSei;
				delete formJS.documentos[index].documentoFormatadoSei;
				delete formJS.documentos[index].urlConsultaPublicaSei;
				delete formJS.documentos[index].invalido;
				formJS.documentosDados = filter(formJS.documentosDados, item => item.id !== doc.id);
				return state.set('formulario', fromJS(formJS));
			}
			return state;
		}

		case LIMPAR_DOCUMENTOS_DADOS: {
			const { ddIndice = -1, dIndice = -1 } = action.payload;
			const form = state.get('formulario');
			const formJS = form && Map.isMap(form) ? form.toJS() : form;
			const { documentosDados, documentos } = formJS;

			if (!isNil(ddIndice) && ddIndice !== -1) {
				const documentoDados = documentosDados[ddIndice];
				if (documentoDados) {
					let newState = state.setIn(['formulario', 'documentosDados', ddIndice, 'dados'], fromJS({}));
					const documento = documentos[dIndice];
					if (documento) {
						newState = newState
							.deleteIn(['formulario', 'documentos', dIndice, 'filename'])
							.deleteIn(['formulario', 'documentos', dIndice, 'originalName'])
							.deleteIn(['formulario', 'documentos', dIndice, 'size']);
					}
					return newState;
				}
			}
			return state;
		}

		case SET_RELOAD: {
			return action.payload ? state.set('reload', fromJS(action.payload)) : state.delete('reload');
		}

		case SET_RESULT: {
			return action.payload ? state.set('result', fromJS(action.payload)) : state.delete('result');
		}

		case SET_LOADING: {
			return action.payload ? state.set('loading', true) : state.delete('loading');
		}

		case SET_ERRORS: {
			return state.set('errors', fromJS(action.payload)).set('loading', false);
		}

		case LIMPA_STORE: {
			return (
				state
					// .setIn(['formulario', 'formData'], fromJS({}))
					.delete('formulario')
					.delete('loading')
					.delete('errors')
			);
		}

		case SET_MOTIVO_DOC: {
			let documento = action.payload;
			const form = state.get('formulario');
			const formJS = form && Map.isMap(form) ? form.toJS() : form;
			const index = findIndex(formJS.documentos, item => item.id === documento.id);
			return state.setIn(['formulario', 'documentos', index], fromJS(documento));
		}

		case SET_DATA_COMPARECIMENTO: {
			return state.setIn(['formulario', 'formData', 'dataComparecimento'], fromJS(action.payload));
		}

		case TOGGLE_DOC: {
			const doc = action.payload;
			let formulario = state.get('formulario');
			const formJS = isImmutable(formulario) ? formulario.toJS() : formulario;
			let doctosJS = formJS.documentos;
			if (doc.invalido) {
				const indexCopia = findIndex(doctosJS, d => d.original === doc.id);
				if (indexCopia > -1) {
					if (doctosJS[indexCopia].filename) {
						const errors = state.get('errors');
						let errorsJS = errors && Map.isMap(errors) ? errors.toJS() : {};
						errorsJS[doc.id] = errorsJS[doc.id] || [];
						errorsJS[doc.id].push('Usuário já adicionou um novo arquivo mas ainda não o enviou ao SEI');
						return state.set('errors', fromJS(errorsJS));
					} else {
						const index = findIndex(doctosJS, d => d.id === doc.id);
						doctosJS[index].invalido = false;
						delete doctosJS[index].motivo;
						doctosJS = doctosJS.filter(d => doc.id !== d.original);
					}
				}
			} else {
				const index = findIndex(doctosJS, d => d.id === doc.id);
				doctosJS[index].invalido = true;
				let { id, idDocumento, tituloDocumento, obrigatorio, ordem, versao } = doc;
				versao += 1;
				ordem += 1;
				id = uuid();
				doctosJS.push({ id, idDocumento, tituloDocumento, obrigatorio, ordem, versao, original: doc.id });
			}
			formJS.documentos = doctosJS;
			return state.set('formulario', fromJS(formJS));
		}

		case UPDATE_STATUS_DOCUMENTO: {
			const { documento, documentoDados } = action.payload;
			const formulario = state.get('formulario');

			const formJS = isImmutable(formulario) ? formulario.toJS() : formulario;
			const { documentos, documentosDados } = formJS;

			const novosDocs = (documentos || []).map(doc => (doc?.id === documento?.id ? documento : doc));

			const novosDocsDados = (documentosDados || []).map(doc => (doc?.id === documento?.id ? documentoDados : doc));
			if (!novosDocsDados.find(doc => doc?.id === documento?.id)) {
				novosDocsDados.push(documentoDados);
			}

			return state
				.setIn(['formulario', 'documentos'], fromJS(novosDocs))
				.setIn(['formulario', 'documentosDados'], fromJS(novosDocsDados));
		}

		case UPDATE_RESPOSTA_EXTRAINFO: {
			let newState = state;
			const { infoId, resposta } = action.payload;
			const form = state.get('formulario');
			const formJS = form && isImmutable(form) ? form.toJS() : form;
			const { formData } = formJS || {};
			const { extraInfo = [] } = formData || {};
			const index = findIndex(extraInfo, item => item.id === infoId);
			if (index > -1) {
				newState = state.setIn(['formulario', 'formData', 'extraInfo', index, 'resposta'], resposta);
			}
			return newState;
		}

		case SET_BPM_TASKS: {
			let newState = cloneDeep(state);
			if (!action.payload) {
				newState = newState.delete('bpmTasks');
			} else {
				newState = newState.setIn(['bpmTasks'], fromJS(action.payload));
			}
			return newState;
		}
		case SET_KC_USER: {
			return state.setIn(['keycloakUser'], fromJS(action.payload));
		}
		case SALVA_ANEXO_LIST: {
			const { property, lista } = action.payload;
			return state.set(property, fromJS(lista));
		}
		case CLEAR_ANEXO_LIST: {
			const { propertyArray } = action.payload;
			let newState = state;
			propertyArray.forEach(property => {
				newState = newState.delete(property);
				return newState;
			});
			return newState;
		}
		case SET_PERSISTENT_STATE: {
			const { path, value } = action.payload;
			const atual = state.getIn(path);
			const equals = compareStates(atual, value, 'id');
			return equals ? state : state.setIn(path, fromJS(value));
		}
		case SET_USUARIO: {
			return state.setIn(['usuario'], fromJS(action.payload));
		}
		case SET_USUARIO_INTERNO: {
			return state.setIn(['usuarioInterno'], fromJS(action.payload));
		}

		default:
			return state;
	}
};
